import {Grid, Grow} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import PropType from "prop-types";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {BaseSection, fromLabel, fromPhone, isYes, TextField, toId, toPhone, toYesNo, TypedPhone, YesNo, Optional, useGlobal, Select} from "up-form";
import {MenuItem} from "@material-ui/core";
import { useworkplaceEducators } from "up-state";

const StudentDetails = ({section}) => {
  const {t} = useTranslation();
  const [countryValidationError, setCountryValidationError] = useState();
  const {data} = useworkplaceEducators();
  const {
    component: {
      section: {
        StudentDetails: {checkPhoneCountries}
      }
    }
  } = useGlobal();

  return (
    <BaseSection section={section} title={t("StudentDetails.title")}>
      <Grid item xs={12} sm={12}>
        <Select name="workplaceEducator" required label="Workplace Educator" fullWidth>
          {(data && data.map((user, i) => (
              <MenuItem key={i} value={user.id}>
                {user.label}
              </MenuItem>
            ))) || <MenuItem>Loading...</MenuItem>}
        </Select>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField required fullWidth name="firstName" label={t("StudentDetails.firstName.label")} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField fullWidth name="middleName" label={t("StudentDetails.middleName.label")} />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField required fullWidth name="lastName" label={t("StudentDetails.lastName.label")} />
      </Grid>

      <Grid item xs={12}>
        <TextField required fullWidth type="email" name="emailAddress" label={t("StudentDetails.email.label")} />
      </Grid>
      <Grid item xs={12}>
        <TypedPhone
          required
          fullWidth
          countries={checkPhoneCountries}
          inputProps={{minLength: 5}}
          onCountryValidation={({message}) => {
            setCountryValidationError(message);
          }}
          label={t("StudentDetails.phone.label")}
          helperText={
            <Grow mountOnEnter unmountOnExit in={!!countryValidationError} timeout={1000}>
              <Alert severity="info">{countryValidationError}</Alert>
            </Grow>
          }
        />
      </Grid>
      <Optional name="StudentDetails.international">
        <Grid item xs={12}>
          <YesNo required name="international" label={t("StudentDetails.international.label")} />
        </Grid>
      </Optional>
    </BaseSection>
  );
};

export default StudentDetails;

StudentDetails.propTypes = {
  form: PropType.string.isRequired,
  section: PropType.string.isRequired
};

export function mapToLead(details) {
  const {emailAddress, firstName, lastName, middleName, international, workplaceEducator} = details;
  return {
    studentStatusLocation: isYes(international) ? "International" : "Domestic", // note uses string for this, app uses id
    emailAddress,
    firstName,
    lastName,
    middleName,
    workplaceEducator,
    ...toPhone(details)
  };
}

export function mapToApplication(details, {metadata: {statusLocations} = {}}) {
  const {emailAddress, firstName, lastName, middleName, international} = details;
  return {
    student: {
      emailAddress,
      firstName,
      lastName,
      middleName,
      ...toPhone(details)
    },
    opportunity: {
      statusLocationId: toId(fromLabel(statusLocations, isYes(international) ? "International" : "Domestic"))
    }
  };
}
export function mapFromApplication({student = {}}, {metadata: {statusLocations}}) {
  const {emailAddress, firstName, lastName, middleName, statusLocation} = student;

  return {
    emailAddress,
    firstName,
    lastName,
    middleName,
    ...fromPhone(student),
    international: toYesNo(statusLocation === toId(fromLabel(statusLocations, "International")))
  };
}
