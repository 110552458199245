import {Grid, MenuItem, withStyles} from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {formValueSelector} from "redux-form";
import {BaseSection, Select, useGlobal, toCourseIntake} from "up-form";
import {useCourses} from "up-state";

/**
 * Choose the course by presenting product options
 * preselect via courseId.productId
 */
const ChooseCourse = withStyles(
  (theme) => ({
    courseItem: {
      whiteSpace: "pre-wrap"
    }
  }),
  {name: "UpChooseCourse"}
)(({form, section, classes}) => {
  const {t} = useTranslation();
  const {providerSlug} = useGlobal();
  const {data: allCourses, pending} = useCourses(providerSlug);
  const selector = formValueSelector(form);
  const industryFilter = useSelector((state) => selector(state, section + ".industryFilter"));
  const course = useSelector((state) => selector(state, section + ".course"));
  const courses =
    allCourses &&
    allCourses
      .filter(
        // only use courses with at least one intake with a campus and start date
        ({intakeList}) => intakeList && intakeList.find(({location, courseStartDate}) => location && courseStartDate)
      )
      .filter(({industry}) => !industryFilter || industry === industryFilter); // If filtering by industry

  const loading = !courses || pending;

  const showCourseSelect = true; // uncomment to disable course re-selection on preselect !(courseId || courseCode || intakeId);
  const displayName = (course) => course && (course.marketingName || course.name);

  return (
    <BaseSection
      section={section}
      title={
        showCourseSelect
          ? t("ChooseCourse.title")
          : course && (
              <>
                Applying for <b>{displayName(course)}</b>
              </>
            )
      }
    >
      {showCourseSelect && (
        <Grid item xs={12}>
          <Select
            name="course"
            fullWidth
            required
            displayEmpty={loading}
            renderValue={(v) => v && (typeof v === "string" ? v : displayName(v))}
            label={loading ? "Loading..." : t("ChooseCourse.course.select.label")}
          >
            {courses &&
              courses.map((product, i) => {
                const {level} = product;
                return (
                  <MenuItem className={classes.courseItem} key={i} value={product}>
                    <Grid container wrap="wrap">
                      <Grid xs={10} item>
                        {displayName(product)}
                      </Grid>
                      {level && (
                        <Grid xs={1} item>
                          Level {level}
                        </Grid>
                      )}
                    </Grid>
                  </MenuItem>
                );
              })}
          </Select>
        </Grid>
      )}
    </BaseSection>
  );
});

export default ChooseCourse;

ChooseCourse.propTypes = {
  form: PropTypes.string.isRequired,
  section: PropTypes.string.isRequired
};

export function mapToLead({course: {productId} = {}}) {
  return {
    productId
  };
}

export function mapFromApplication(
  {opportunity: {intakeCRMId, productCRMId} = {}},
  {courses}
) {
  const {course} = intakeCRMId || productCRMId ? toCourseIntake({intakeCRMId, productCRMId}, courses) : {};
  return {
    course
  };
}
