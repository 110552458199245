import {Grid} from "@material-ui/core";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {formValueSelector} from "redux-form";
import {
  AddressAutoComplete,
  BaseSection,
  Optional,
  TextField,
  toAddress,
  useGlobal,
  Select
} from "up-form";
import {MenuItem} from "@material-ui/core";
import { useMetadata } from "up-state";

const AddressFields = ({prefix, required = (field) => false, section, change}) => {
  const {t} = useTranslation(),
    {
      component: {
        section: {Address: {autoComplete, preferCountry} = {}}
      },
      google: {apiKey}
    } = useGlobal();
  const {data: {nzTerritorialLocalAreas}} = useMetadata();

  return (
    <>
      {autoComplete && (
        <Grid item xs={12}>
          <AddressAutoComplete
            required={required}
            fullWidth
            label={t("Address.autoComplete.label")}
            preferCountry={preferCountry}
            onChange={({
              flatUnitDetails,
              streetOrLotNumber,
              buildingPropertyName,
              streetAddress,
              suburb,
              city,
              state,
              postcode
            }) => {
              change(
                `${section}.${prefix}.streetAddress`,
                [flatUnitDetails, buildingPropertyName, streetOrLotNumber, streetAddress].filter((v) => !!v).join(" ")
              );
              change(`${section}.${prefix}.suburb`, suburb);
              change(`${section}.${prefix}.city`, city);
              change(`${section}.${prefix}.state`, state);
              change(`${section}.${prefix}.postcode`, postcode);
            }}
            apiKey={apiKey}
          />
        </Grid>
      )}

      <Grid item xs={12} sm={6}>
        <TextField
          required={required("streetAddress")}
          fullWidth
          name={prefix + ".streetAddress"}
          label={t("Address.streetAddress.label")}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField fullWidth required={required("suburb")} name={prefix + ".suburb"} label={t("Address.suburb.label")} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField fullWidth required={required("city")} name={prefix + ".city"} label={t("Address.city.label")} />
      </Grid>
      <Optional name="Address.state">
        <Grid item xs={12} sm={6}>
          <TextField fullWidth required={required("state")} name={prefix + ".state"} label={t("Address.state.label")} />
        </Grid>
      </Optional>
      <Grid item xs={12} sm={6}>
        <TextField fullWidth required={required("postcode")} name={prefix + ".postcode"} label={t("Address.postcode.label")} />
      </Grid>
      <Grid item xs={12} sm={12}>
        <Select name="region" required label="Region" fullWidth>
          {(nzTerritorialLocalAreas && nzTerritorialLocalAreas.map((user, i) => (
              <MenuItem key={i} value={user.id}>
                {user.label}
              </MenuItem>
            ))) || <MenuItem>Loading...</MenuItem>}
        </Select>
      </Grid>
      <Grid item xs={12} />
    </>
  );
};

const EmployerAddress = ({form, section, change}) => {
  const selector = formValueSelector(form);
  const employerDetails = useSelector((state) => selector(state, "employerDetails.employerObject"));
  return (
    (employerDetails && employerDetails.accountid === "1") ? (
    <BaseSection section={section} >
      <AddressFields
        prefix="baseAddress"
        required={(field) => ["streetAddress", "city", "postcode"].includes(field)}
        {...{section, change}}
      />
    </BaseSection>) : null
  );
};

EmployerAddress.propTypes = {
  change: PropTypes.any,
  form: PropTypes.any,
  section: PropTypes.string
};

export default EmployerAddress;

export function mapToEmployer(employerAddress, {metadata}) {
  const address = toAddress(employerAddress.baseAddress, metadata);
  address["region"] = employerAddress.region;
  return {
    employerAddress: address
  };
}
