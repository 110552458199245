import {Grid} from "@material-ui/core";
import PropType from "prop-types";
import React, {useState, useEffect} from "react";
import {BaseSection, TextField, Select} from "up-form";
import {MenuItem} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useMetadata, useWorkplaceEmployers } from "up-state";
import isEqual from "lodash/isEqual";
import { useSelector} from "react-redux";
import {formValueSelector} from "redux-form";

const EmployerDetails = ({section, change, form}) => {
	const [employerOption, setEmployerOption] = useState(null);
	const defaultOption = {"accountid": "1", "name": "Employer not in list"};
	const {data: {value: employerList} = {}, pending} = useWorkplaceEmployers("809730007");
	const {data: {industryCodes}} = useMetadata();
	const selector = formValueSelector(form);
	const currentEmployer = useSelector((state) => selector(state, "employerDetails.employerObject"));

	if (employerList && !isEqual(employerList[0], defaultOption)) {
		employerList.unshift(defaultOption);
	};
	
	useEffect(() => {
	if (employerOption) {
		change(`${section}.employerObject`, employerOption);
		change(`${section}.employer`, employerOption.name);
		}
	}, [employerOption, change, section]);

	return (
		<BaseSection section={section} title="Business Details">
			<Grid item xs={12} sm={12}>
				<Autocomplete
					loading={pending}
					options={employerList || []}
					value={currentEmployer || null}
					getOptionLabel={(option) => option.name}
					onChange={(event, newValue) => {
						setEmployerOption(newValue);
					}}
					filterOptions={(options, params) => {
						if (!employerList || employerList.length === 0) {
							return [];
						}
						// Add the defaultOption at the start
						const filtered = employerList && employerList.filter((option) => option.name.toLowerCase().includes(params.inputValue.toLowerCase()));
						
						if (params.inputValue !== '' && filtered.length > 0 && filtered[0].name !== defaultOption.name) {
							filtered.unshift(defaultOption);
						}
						
						return filtered;
					}}
					renderInput={(params) => <TextField {...params} required name="employer" label="Employer" />}
				/>
			</Grid>
		{((currentEmployer && currentEmployer.accountid === "1") && 
			<>
				<Grid item xs={12} sm={6}>
					<TextField required fullWidth name="tradingName" label="Trading Name" />
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField required fullWidth name="legalName" label="Legal Name"/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField required fullWidth name="nzbn" label="NZ Business Number (NZBN)" />
				</Grid>
				<Grid item xs={12} sm={6}>
					<Select name="industry" required label="Industry" fullWidth>
					{(industryCodes && industryCodes.filter((option) => {return option.id === "34" || option.id === "35"}).map((option, i) => (
						<MenuItem key={i} value={option.id}>
							{option.id === "34" ? "Residential Construction" : option.id === "35" ? "Commercial Construction" : option.label}
						</MenuItem>
						))) || <MenuItem>Loading...</MenuItem>}
					</Select>
				</Grid>
			</>
		)}
		</BaseSection>
  	);
};

export default EmployerDetails;

EmployerDetails.propTypes = {
  form: PropType.string.isRequired,
  section: PropType.string.isRequired
};

export function mapToEmployer(employerDetails) {
    const {tradingName, legalName, nzbn, employerObject, industry} = employerDetails
    return {
		employerAccount: 
			employerObject && employerObject.accountid === "1" ? 
				{
					tradingName,
					legalName,
					nzbn,
					industry
				} :
				{
					employerid: employerObject.accountid
				}
    };
}