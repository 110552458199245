import {Grid} from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import {useTranslation} from "react-i18next";
import {BaseSection, fromId, isYes, toYesNo, YesNo} from "up-form";

const Language = ({section, form}) => {
  const {t} = useTranslation();
  const prefix = "Language";

  return (
    <BaseSection section={section} title={t(`${prefix}.title`)}>
      <Grid item xs={12} sm={12}>
          <YesNo required label="Is English your first language?" name="isEnglishFirstLanguage" />
      </Grid>
    </BaseSection>
  );
};

Language.propTypes = {
  form: PropTypes.string.isRequired,
  section: PropTypes.string.isRequired
};

export default Language;

export function mapToApplication(
  {isEnglishFirstLanguage}
) {
  return {
    language: {
      isEnglishFirstLanguage: isYes(isEnglishFirstLanguage)
    }
  };
}

export function mapFromApplication(
  {language: {requiresEnglishHelp, languageSpokenAtHomeId, spokenEnglishId, requiresLanguageLiteracyNumeracyHelp} = {}},
  {metadata: {languages, spokenEnglishLevels}}
) {

  return {
    languageSpokenAtHome: fromId(languages, languageSpokenAtHomeId),
    requiresEnglishHelp: toYesNo(requiresEnglishHelp) || undefined,
    requiresLanguageLiteracyNumeracyHelp: toYesNo(requiresLanguageLiteracyNumeracyHelp) || undefined,
    spokenEnglish: fromId(spokenEnglishLevels, spokenEnglishId)
  };
}
