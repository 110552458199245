import {Box, CircularProgress,Grid, withStyles} from "@material-ui/core";
import PropType from "prop-types";
import {Trans, useTranslation} from "react-i18next";
import {FiUpload} from "react-icons/fi";
import {BaseSection, DocumentsUpload, Typography, useGlobal} from "up-form";
import { useSelector} from "react-redux";

const Documents = withStyles((theme) => {
  return {};
})(({form, section, classes}) => {
  const {t} = useTranslation(),
    {
      component: {
        section: {Documents: {accept, maxFileSize} = {}}
      }
    } = useGlobal();

    const { pending: pendingApplication}  = useSelector((state) => state.application);

  return (
    <BaseSection section={section}>
      <Grid item xs={12}>
        <Typography variant="body1" component="div">
          <Trans i18nKey="Documents.header.text" />
        </Typography>
      </Grid>
      <Grid item xs={12}>
      {pendingApplication ? 
      
        (<Box padding={5}>
          <CircularProgress variant="indeterminate" />
          <Typography variant="body1">Loading...</Typography>
        </Box>) : 
        <DocumentsUpload
        name="files"
        accept={accept}
        maxFileSize={maxFileSize}
        dropzoneLabel={
          <Grid container direction="column" alignItems="center" justifyContent="center">
          <FiUpload size="2em" />
          <Typography variant="h6">{t("Documents.dropzone.label")}</Typography>
          <Typography variant="body1" align="center">
            <Trans i18nKey="Documents.dropzone.accept" components={[<b />]} />
          </Typography>
          <Typography variant="body1">{t("Documents.dropzone.sizeLimit", {maxSize: maxFileSize})}</Typography>
          <Typography variant="body1">{t("Documents.dropzone.uploaders")}</Typography>
          </Grid>
        }
        />
        }
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" component="div">
          <Trans i18nKey="Documents.footer.text" />
        </Typography>
      </Grid>
    </BaseSection>
  );
});

export default Documents;

Documents.propTypes = {
  form: PropType.string.isRequired,
  section: PropType.string.isRequired
};
