import {CircularProgress, Grid, Paper} from "@material-ui/core";
import {isUndefined, get} from "lodash";
import debounce from "lodash/throttle";
import moment from "moment";
import PropType from "prop-types";
import {useCallback, useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {formValueSelector} from "redux-form";
import upApi from "up-api";
import {
  Activity,
  BaseSection,
  fromId,
  isNo,
  isYes,
  LearningNumber,
  Optional,
  Qualification,
  School,
  SchoolLevel,
  schoolTypes,
  TextField,
  toId,
  toLabel,
  toYesNo,
  Typography,
  useExcludedSet,
  useGlobal,
  USI,
  Year,
  YesNo
} from "up-form";
import {useMetadata} from "up-state";
import { Autocomplete } from "@material-ui/lab";

const tertiaryTypes = new Set(["Private Training Establishment", "Polytechnic", "University"].map((name) => schoolTypes[name]));

const Education = ({form, section, change}) => {
  const {t} = useTranslation();
  const [fetchingFeesFree, setFetchingFeesFree] = useState(false);
  const [highSchoolOption, setHighSchoolOption] = useState(null);
  const selector = formValueSelector(form);
  const course = useSelector((state) => selector(state, "courseDetails.course"));
  const intake = useSelector((state) => selector(state, "courseDetails.intake"));
  const {data: {courseClassifications, schools} = {}, pending} = useMetadata();
  const nzHighSchool = useSelector((state) => selector(state, section + ".nzHighSchool"));
  const firstTertiary = useSelector((state) => selector(state, section + ".firstTertiary"));
  const feesFreeEligibility = useSelector((state) => selector(state, section + ".feesFreeEligibility"));
  const currentSchool = useSelector((state) => selector(state, section + ".highSchoolObject"));
  const changeEligibility = useCallback(
    (eligibility) => eligibility !== change(`${section}.feesFreeEligibility`, eligibility),
    [change, section]
  );
  const {
    providerSlug,
    component: {
      section: {Education: {checkFeesFree} = {}}
    }
  } = useGlobal();
  const {courseClassificationCode} = course || {};
  const isMicroCredential =
    courseClassificationCode &&
    courseClassifications &&
    /Micro-credential/i.test(toLabel(fromId(courseClassifications, courseClassificationCode)));
  // For YBSC, exclude previous occupation and last year unless its a micro cred course
  const exclusions = new Set([
    ...useExcludedSet(),
    ...(providerSlug === "yoobee-short-courses" && !isMicroCredential
      ? ["Education.previousOccupation", "Education.lastYearOfSchool"]
      : [])
  ]);

  useEffect(() => {
    if (highSchoolOption) {
      change(`${section}.highSchoolObject`, highSchoolOption);
      change(`${section}.highschool`, highSchoolOption.label);
      }
    }, [highSchoolOption, change, section]);

  const fetchFeesFree = debounce((nsiNumber) => {
    if (course && intake && nsiNumber) {
      setFetchingFeesFree(true);
      upApi
        .feesFreeEligibility(nsiNumber)
        .then((eligibilityList) => {
          const startYear = intake.courseStartDate && moment(intake.courseStartDate).year();
          // TODO: course.level?
          if (course.level && course.level >= 3) {
            const {SAC3: {eligibility} = {}} =
              (
                (eligibilityList && eligibilityList.find(({nsn}) => nsn === nsiNumber)) || {
                  elegibilty: []
                }
              ).eligibility.find(({year}) => year === startYear) || {};
            changeEligibility({Eligible: "yes", "Not Eligible": "no", Maybe: "maybe"}[eligibility] || undefined);
          } else {
            changeEligibility("no");
          }
        })
        .catch(() => changeEligibility("maybe"))
        .finally(() => setFetchingFeesFree(false));
    } else {
      changeEligibility(null);
    }
  }, 1000);

  return (
    <BaseSection section={section} title={t("Education.title")}>
      {!exclusions.has("Education.previousOccupation") && (
        <Grid item xs={12}>
          <Activity
            fullWidth
            required={!exclusions.has("Education.highestQualification.required")}
            label={t("Education.previousOccupation.label")}
            name="previousOccupation"
          />
        </Grid>
      )}
      <Optional name="Education.isAtSchool">
        <Grid item xs={12}>
          <YesNo label={t("Education.isAtSchool.label")} name="isAtSchool" />
        </Grid>
      </Optional>
      <Optional name="Education.nzHighSchool">
        <Grid item xs={12}>
          <YesNo required label={t("Education.nzHighSchool.label")} name="nzHighSchool" />
        </Grid>
      </Optional>
      {nzHighSchool === "no" || exclusions.has("Education.nzHighSchool") ? ( // if user selected non NZ, or we don't give them the option for, use 'overseas' school
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label={t("Education.lastOverseasSchool.label")} name="lastOverseasSchool" />
        </Grid>
      ) : (
        nzHighSchool === "yes" && (
          <>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                loading={pending}
                options={schools || []}
                value={currentSchool || null}
                getOptionLabel={(option) => option.label || ""}
                onChange={(event, newValue) => {
                  setHighSchoolOption(newValue);
                }}
                filterOptions={(options, params) => {
                  if (!schools || schools.length === 0) {
                    return [];
                  }
                  const filtered = schools && schools.filter((option) => option.type === "809730005" && option.label.toLowerCase().includes(params.inputValue.toLowerCase())).sort(({label: a}, {label: b}) => (a || "").localeCompare(b));
    
                  return filtered;
                }}
                renderInput={(params) => <TextField {...params} required name="highschool" label={t("Education.highSchool.label")} />}
              />
            </Grid>
            <Optional name="Education.currentNzqaYearLevel">
              <Grid item xs={12} sm={6}>
                <TextField
                  type="number"
                  inputProps={{min: 1, max: 15}}
                  fullWidth
                  label={t("Education.currentNzqaYearLevel.label")}
                  name="currentNzqaYearLevel"
                />
              </Grid>
            </Optional>
          </>
        )
      )}
      <Grid item xs={12} />
      <Optional name="Education.lastYearOfSchool">
        <Grid item xs={12} sm={6}>
          <Year
            fullWidth
            required
            label={t("Education.lastYearOfSchool.label")}
            name="lastYearOfSchool"
          />
        </Grid>
      </Optional>
      <Optional name="Education.highestCompletedSchoolLevel">
        <Grid item xs={12} sm={6}>
          <SchoolLevel name="highestCompletedSchoolLevel" label={t("Education.highestCompletedSchoolLevel.label")} />
        </Grid>
      </Optional>
      <Optional name="Education.highestQualification">
        <Grid item xs={12} sm={6}>
          <Qualification
            fullWidth
            required
            label={t("Education.highestQualification.label")}
            name="highestQualification"
          />
        </Grid>
      </Optional>
      {exclusions.has("Education.firstTertiary") && (
        <Optional name="Education.yearExpectToCompleteAcademicReqmts">
          <Grid item xs={12} sm={6}>
            <Year
              required={!exclusions.has("Education.yearExpectToCompleteAcademicReqmts.required")}
              label={t("Education.yearExpectToCompleteAcademicReqmts.label")}
              name="yearExpectToCompleteAcademicReqmts"
              fullWidth
            />
          </Grid>
        </Optional>
      )}
      <Optional name="Education.nsiNumber">
        <Grid item xs={12}>
          <LearningNumber
            fullWidth
            label={t("Education.nsiNumber.label")}
            name="nsiNumber"
            onChange={(event) => checkFeesFree && fetchFeesFree(event.target.value)}
          />
        </Grid>
      </Optional>
      <Optional name="Education.usiNumber">
        <Grid item xs={12}>
          <USI fullWidth label={t("Education.usiNumber.label")} name="usiNumber" />
        </Grid>
      </Optional>
      {checkFeesFree && (feesFreeEligibility || fetchingFeesFree) && (
        <Grid item xs={12} container alignItems="center" component={Paper} style={{margin: "1em"}}>
          <Grid item xs={12} sm={2}>
            <img src="/fees-free-logo-black-small.png" alt="FeesFree"></img>
          </Grid>
          <Grid item xs={12} sm={10}>
            {fetchingFeesFree ? (
              <CircularProgress variant="indeterminate" />
            ) : (
              <Typography variant="h6" color="textSecondary" component="div">
                {t(`ApplicationSummary.feesFree.${feesFreeEligibility}`)}
              </Typography>
            )}
          </Grid>
        </Grid>
      )}
      <Optional name="Education.firstTertiary">
        <Grid item xs={12}>
          <YesNo label={t("Education.firstTertiary.label")} name="firstTertiary" />
        </Grid>
        {firstTertiary === "no" && (
          <>
            <Grid item xs={12}>
              <School
                required
                fullWidth
                label={t("Education.previousTertiary.organisation.label")}
                name="previousTertiaryOrganisation"
                filter={({type}) => {
                  return tertiaryTypes.has(type);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Year required label={t("Education.previousTertiary.enrolmentYear.label")} name="yearOfFirstEnrolment" fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Year
                required
                label={t("Education.previousTertiary.qualificationYear.label")}
                name="yearExpectToCompleteAcademicReqmts"
                fullWidth
              />
            </Grid>
          </>
        )}
      </Optional>
    </BaseSection>
  );
};

export default Education;

Education.propTypes = {
  form: PropType.string.isRequired,
  section: PropType.string.isRequired
};

export function mapToApplication({
  currentNzqaYearLevel,
  firstTertiary,
  highestQualification,
  highschool,
  highSchoolObject,
  isAtSchool,
  isRplStudent,
  highestCompletedSchoolLevel,
  lastYearOfSchool,
  lastOverseasSchool,
  nsiNumber,
  nzHighSchool,
  previousOccupation,
  previousTertiaryOrganisation,
  usiNumber,
  yearExpectToCompleteAcademicReqmts,
  yearOfFirstEnrolment
}) {
  return {
    education: {
      currentNzqaYearLevel,
      isAtSchool: isYes(isAtSchool),
      highestCompletedSchoolLevelId: toId(highestCompletedSchoolLevel),
      highestSecondaryQualificationId: toId(highestQualification),
      lastOverseasSchool: isNo(nzHighSchool) ? lastOverseasSchool : undefined,
      lastSecondarySchoolId: isYes(nzHighSchool) ? toId(highSchoolObject) : undefined,
      lastYearOfSecondarySchool: lastYearOfSchool,
      hasAttendedPreviousTertiary: isNo(firstTertiary),
      ...(isNo(firstTertiary) && {
        previousTertiaryOrganisationId: toId(previousTertiaryOrganisation),
        yearOfFirstEnrolment: yearOfFirstEnrolment
      }),
      yearExpectToCompleteAcademicReqmts: yearExpectToCompleteAcademicReqmts
    },
    history: {
      previousOccupationId: toId(previousOccupation)
    },
    student: {
      uniqueStudentIdentifier: usiNumber,
      nsiNumber: nsiNumber
    }
  };
}
export function mapFromApplication(
  {
    education: {
      currentNzqaYearLevel,
      hasAttendedPreviousTertiary,
      highestSecondaryQualificationId,
      highestCompletedSchoolLevelId,
      isAtSchool,
      lastOverseasSchool,
      lastSecondarySchoolId,
      lastYearOfSecondarySchool,
      previousTertiaryOrganisationId,
      uniqueStudentIdentifier,
      yearExpectToCompleteAcademicReqmts,
      yearOfFirstEnrolment
    } = {},
    history: {previousOccupationId} = {},
    student: {nsiNumber} = {}
  },
  {metadata: {activities, schools, qualifications, schoolLevels}}
) {
  return {
    currentNzqaYearLevel,
    nzHighSchool: toYesNo(!!lastSecondarySchoolId),
    firstTertiary: toYesNo(!hasAttendedPreviousTertiary),
    highschool: fromId(schools, lastSecondarySchoolId),
    isAtSchool: toYesNo(isAtSchool),
    highestQualification: fromId(qualifications, highestSecondaryQualificationId),
    highestCompletedSchoolLevel: fromId(schoolLevels, highestCompletedSchoolLevelId),

    lastOverseasSchool: lastOverseasSchool,
    lastYearOfSchool: lastYearOfSecondarySchool,
    nsiNumber: nsiNumber,
    previousOccupation: fromId(activities, previousOccupationId),
    previousTertiaryOrganisation: fromId(schools, previousTertiaryOrganisationId),
    usiNumber: uniqueStudentIdentifier,
    yearExpectToCompleteAcademicReqmts: yearExpectToCompleteAcademicReqmts,
    yearOfFirstEnrolment
  };
}

export function isComplete(application, excluded) {
  // We can only call the section complete if any of the mandatory fields have been set, otherwise we can't just skip the section
  return (
    application &&
    [
      "history.previousOccupationId",
      "education.yearExpectToCompleteAcademicReqmts",
      "education.lastYearOfSchool",
      "education.lastOverseasSchool",
      "education.lastSecondarySchoolId",
      "education.previousTertiaryOrganisation"
    ].findIndex((field) => !isUndefined(get(application, field))) >= 0
  );
}
 