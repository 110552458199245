/**
 * Signature section
 */
import {Grid} from "@material-ui/core";
import PropType from "prop-types";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {formValueSelector} from "redux-form";
import {BaseSection, fromId, fromPhone, Relationship, TextField, toId, toPhone, TypedPhone} from "up-form";
import {isMinor} from "../../business/enrol";

const Signature = ({form, section}) => {
  const {t} = useTranslation(),
    selector = formValueSelector(form),
    dateOfBirth = useSelector((state) => selector(state, "personal.dateOfBirth"));

  return (
    <>
      {isMinor(dateOfBirth) && (
        <BaseSection section={section} title={t("Signature.title")}>
          <Grid item xs={12} sm={6}>
            <TextField required fullWidth name="guardian.firstName" label={t("Signature.guardian.firstName.label")} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField required fullWidth name="guardian.lastName" label={t("Signature.guardian.lastName.label")} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Relationship
              required
              fullWidth
              name="guardian.relationship"
              label={t("Signature.guardian.relationship.label")}
              filter={({label}) => !["Child", "Grandchild"].includes(label) /* can't be older than me! */}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField required fullWidth type="email" name="guardian.emailAddress" label={t("Signature.guardian.email.label")} />
          </Grid>
          <Grid item xs={12}>
            <TypedPhone label={t("Signature.guardian.phone.label")} namePrefix="guardian" required />
          </Grid>
        </BaseSection>
      )}
    </>
  );
};

export default Signature;

Signature.propTypes = {
  form: PropType.string.isRequired,
  section: PropType.string.isRequired
};

export function mapToApplication({guardian} = {}) {
  const {firstName, lastName, emailAddress, relationship} = guardian || {};
  return {
    contacts: {
      guardian: {
        firstName,
        lastName,
        emailAddress,
        ...toPhone(guardian),
        relationshipToStudentId: toId(relationship)
      }
    }
  };
}
export function mapFromApplication({contacts: {guardian} = {}}, {metadata: {relationships}}) {
  const {firstName, lastName, emailAddress, relationshipToStudentId} = guardian || {};
  return {
    guardian: {
      firstName,
      lastName,
      emailAddress,
      relationship: fromId(relationships, relationshipToStudentId),
      ...fromPhone(guardian)
    }
  };
}
