import React, {useState, useEffect} from "react";
import {Paper, Grid, withStyles} from "@material-ui/core";
import {formValueSelector} from "redux-form";
import {useSelector} from "react-redux";
import RoomOutlined from "@material-ui/icons/RoomOutlined";
import Person from "@material-ui/icons/Person";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import {FiAward} from "react-icons/fi";
import {useTranslation} from "react-i18next";
import upApi from "up-api";
import {IconContext} from "react-icons/lib";
import {Typography} from "up-form";
/*
const CourseDetailsAndFees = ({ course }) => {
  return <>COURSE DETAILS...</>;
};
*/
const ApplicationSummary = withStyles((theme) => {
  return {
    root: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(1),
      transition: "width 5s, height 5s"
    },
    name: {
      textTransform: "none",
      paddingBottom: "1em",
      borderBottom: `solid 1px ${theme.palette.divider}`
    },
    course: {
      textTransform: "none"
    },
    icon: {
      marginRight: "0.5em",
      verticalAlign: "middle",
      color: theme.palette.secondary.main
    }
  };
})(({form, section, className, classes}) => {
  const selector = formValueSelector(form),
    {firstName, lastName} = useSelector((state) => selector(state, "details") || {}),
    {course} = useSelector((state) => selector(state, "courseDetails") || {}),
    {campus} = useSelector((state) => selector(state, "intake") || {}),
    {feesFreeEligibility} = useSelector((state) => selector(state, "education") || {}),
    showSummary = (firstName && lastName) || course,
    [isUserVerified, setVerified] = useState(false),
    {t} = useTranslation();

  useEffect(() => {
    upApi.getAccessToken().then((token) => setVerified(!!token));
  });

  return (
    <>
      {showSummary && (
        <Paper classes={{root: classes.root}} elevation={1} className={className}>
          <Grid container component="aside" spacing={2} aria-label="application summary">
            {firstName && lastName && (
              <Grid item xs={12}>
                <Typography variant="body1" className={classes.name}>
                  {isUserVerified ? (
                    <VerifiedUser color="secondary" className={classes.icon} />
                  ) : (
                    <Person color="secondary" className={classes.icon} />
                  )}
                  {`${firstName} ${lastName}`}
                </Typography>
              </Grid>
            )}
            {course && (
              <Grid item xs={12}>
                {course.marketingName && (
                  <Typography variant="h6" className={classes.course}>
                    {course.marketingName}
                  </Typography>
                )}
                {course.name && (
                  <Typography variant="body1" className={classes.course}>
                    <IconContext.Provider
                      value={{
                        size: "24px",
                        attr: {strokeWidth: 3}
                      }}
                    >
                      <FiAward className={classes.icon} />
                    </IconContext.Provider>
                    {course.name}
                  </Typography>
                )}
              </Grid>
            )}

            {campus && (
              <Grid item sm={3} lg={12}>
                <Typography variant="body1">
                  <RoomOutlined className={classes.icon} />
                  {campus}
                </Typography>
              </Grid>
            )}
            {feesFreeEligibility && (
              <Grid item sm={3} lg={12} container alignItems="center">
                <Grid item xs={4}>
                  <img src="/fees-free-logo-black-small.png" alt="FeesFree"></img>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body1" component="div">
                    {t(`ApplicationSummary.feesFree.${feesFreeEligibility}`)}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Paper>
      )}
    </>
  );
});

export default ApplicationSummary;
