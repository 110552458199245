/**
 * Form for Declaration step
 */
import React from "react";
import {Link, withStyles, Box, Paper, Grid} from "@material-ui/core";
import {useTranslation, Trans} from "react-i18next";
import {formValueSelector} from "redux-form";
import {useSelector} from "react-redux";
import ApplicationSummary from "../ApplicationSummary";
import ThumbUp from "@material-ui/icons/ThumbUp";
import {Typography, useGlobal} from "up-form";

const Complete = withStyles(
  (theme) => ({
    bar: {
      padding: theme.spacing(1),
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.getContrastText(theme.palette.primary.dark),
      fontSize: "small",
      position: "fixed",
      overflow: "visible",
      width: "100%",
      left: 0
    },
    thumb: {
      verticalAlign: "text-top",
      marginLeft: ".5em"
    },
    paragraph: {},
    thankYou: {
      fontSize: "large",
      fontWeight: "600"
    },
    email: {
      color: theme.palette.primary.main
    }
  }),
  {name: "UpComplete"}
)(({form, classes}) => {
  const {t} = useTranslation();
  const selector = formValueSelector(form);
  const firstName = useSelector((state) => selector(state, "details.firstName"));
  const {providerSlug} = useGlobal();
  const {employerDeclarationChoice} = useSelector((state) => selector(state, "employerDeclaration") || {}); 
  const displayArray = employerDeclarationChoice === "809730001" ? ["needHelp"] : ["whatNext", "needHelp"];
  return (
    <>
      <Typography className={classes.bar} variant="h6">
        {t("Complete.bar.text")}
        <ThumbUp className={classes.thumb} />
      </Typography>
      <Box paddingTop={7} marginBottom={5}>
        <Paper elevation={1}>
          <Box padding={3} marginBottom={3}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={6}>
                <Typography variant="h3" gutterBottom>
                  {t(`Complete.thankYou.title`, {name: firstName})}
                </Typography>
                {(employerDeclarationChoice === "809730000") ? 
                  <Typography variant="body2" gutterBottom color="primary" className={classes.thankYou} component="div">
                    <Trans i18nKey={`Complete.thankYou.text`} />
                  </Typography> :
                  <Typography variant="body2" gutterBottom color="primary" className={classes.thankYou} component="div">
                    <Trans i18nKey={`Complete.emailToEmployer.text`} />
                  </Typography>}
              </Grid>
              <Grid item xs={6}>
                <svg width="100%" viewBox="0 0 217 79" className={classes.email}>
                  <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="T-CONFIRMATION" transform="translate(-416.000000, -237.000000)" stroke="currentColor" strokeWidth="1.5">
                      <g id="Group-2" transform="translate(417.000000, 238.000000)">
                        <circle
                          id="Oval"
                          fill="#FFFFFF"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          cx="189"
                          cy="26"
                          r="26"
                        ></circle>
                        <polyline id="Path" strokeLinecap="round" strokeLinejoin="round" points="188 11 188 27.5 198 33"></polyline>
                        <path
                          d="M129.5,35 L173.5,35 C176.525,35 179,37.3625 179,40.25 L179,71.75 C179,74.6375 176.525,77 173.5,77 L129.5,77 C126.475,77 124,74.6375 124,71.75 L124,40.25 C124,37.3625 126.475,35 129.5,35 Z"
                          id="Path"
                          fill="#FFFFFF"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                        <polyline id="Path" strokeLinecap="round" strokeLinejoin="round" points="179 40 151.5 59 124 40"></polyline>
                        <line
                          x1="109.758333"
                          y1="40.5"
                          x2="64.4791667"
                          y2="40.5"
                          id="Line-Copy"
                          strokeLinecap="square"
                          transform="translate(87.000000, 40.500000) scale(1, -1) translate(-87.000000, -40.500000) "
                        ></line>
                        <line
                          x1="109.757166"
                          y1="55.5"
                          x2="32.4814815"
                          y2="55.5"
                          id="Line-Copy-3"
                          strokeLinecap="square"
                          transform="translate(71.000000, 55.500000) scale(1, -1) translate(-71.000000, -55.500000) "
                        ></line>
                        <line
                          x1="109.521739"
                          y1="70.5"
                          x2="0.47826087"
                          y2="70.5"
                          id="Line"
                          strokeLinecap="square"
                          transform="translate(55.000000, 70.500000) scale(1, -1) translate(-55.000000, -70.500000) "
                        ></line>
                      </g>
                    </g>
                  </g>
                </svg>
              </Grid>
            </Grid>
          </Box>
        </Paper>
        {displayArray.map((key, i) => (
          <React.Fragment key={i}>
            <Typography variant="h5" gutterBottom>
              {t(`Complete.${key}.title`, {name: firstName})}
            </Typography>
            <Typography variant="body1" gutterBottom className={classes.paragraph} component="div">
              <Trans
                i18nKey={`Complete.${key}.text`}
                components={{
                  homeLink: <Link href={`/${providerSlug}`} />
                }}
              />
            </Typography>
          </React.Fragment>
        ))}
        <ApplicationSummary form={form} />
      </Box>
    </>
  );
});

export default Complete;
