import {CircularProgress} from "@material-ui/core";
import {merge} from "lodash";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {Redirect, useLocation} from "react-router";
import {useGlobal, useQuery} from "up-form";
import {application, useApplication, useCourses} from "up-state";
import ApprenticeshipForm from "../form/ApprenticeshipForm";
/**
 * Enrolment form, initialising from query params id provided
 * @param {*} param0
 */
export const Enrol = ({header, defaultStep}) => {
	const {pid, iid, lid, cid} = useQuery();
	const {hash} = useLocation();
	const asCleanId = (id) => id && id !== "00000000-0000-0000-0000-000000000000" && id.toLowerCase();
	const productId = pid,
		courseId = asCleanId(cid),
		intakeId = asCleanId(iid),
		leadId = asCleanId(lid);
	const {providerSlug} = useGlobal();
	const dispatch = useDispatch();
	const {data: courses, error: coursesError} = useCourses(providerSlug);
	const {data: currentApplication} = useApplication();
	const [error, setError] = useState(null);
	const [warning, setWarning] = useState(null);
	const [loading, setLoading] = useState(courseId || intakeId || productId);

	useEffect(() => {
		if (coursesError) {
			setError(coursesError.message);
		} else if (courses) {
			setLoading(false);
			if (intakeId) {
				// preselected intake, check it exists
				const intake = courses.flatMap((course) => course.intakeList || []).find(({intakeCRMId}) => intakeCRMId === intakeId);
				if (intake) {
					// Intake found, set details in application state
					dispatch(
						application.fulfilled(
							merge(
								{
									opportunity: {
										intakeCRMId: intake.intakeCRMId
									}
								},
								currentApplication
							)
						)
					);
				} else {
					setWarning({
						status: "404",
						message: `Course intake not found: ${intakeId}`
					});
				}
			} else if (productId || courseId) {
				// preselected course by product code or courseId , check it exists
				const course = courses.find(
					(course) => (productId && course.productId === productId) || (courseId && course.productCRMId === courseId)
				);
				if (course) {
					// Course found, set details in application state
					dispatch(
						application.fulfilled(
							merge(
								{
									opportunity: {
										// This isn't correct for the schema as we can't just set a product on opportunity (will ignore on mapping)
										productCRMId: course.productCRMId,
										intakeCRMId: null
									}
								},
								currentApplication
							)
						)
					);
				} else {
					setWarning({
						status: "404",
						message: `Course not found: ${[productId, courseId].filter((v) => !!v).join(" or ")}`
					});
				}
			}
		}
		// Note: omit currentApplication from deps coz we don't want to listen for change
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [courseId, intakeId, productId, courses, dispatch]);

	warning && console.warn(warning); // may want to render something but for time being just log

	return (
    <>
      {loading && <CircularProgress />}
      {!loading && !error && (
        <ApprenticeshipForm
          leadId={leadId}
          headerContainer={header}
          defaultStep={(hash && hash.slice(1)) || defaultStep}
        />
      )}
      {error && (
        <Redirect to={`/error/${error.status}?message=${error.message}`} />
      )}
    </>
  );
};;

Enrol.propTypes = {
	defaultStep: PropTypes.any, // Index of step to start form wizard on
	header: PropTypes.any, // Component ref to append step headings to
	sessionId: PropTypes.string //SessionID to initialise from (null to force new session) or it will use sessionStorage
};
