/**
 * Form for Summary section
 */
import PropType from "prop-types";
import {BaseSection, ChooseOne, Typography, Checkbox, useGlobal} from "up-form";
import {useApplication} from "up-state";
import {Grid, Paper, Box} from "@material-ui/core";
import moment from "moment";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {formValueSelector} from "redux-form";
import {acceptance} from "redux-form-validators";
import {Trans, useTranslation} from "react-i18next";
import {useEffect, useMemo} from "react";

const EmployerDeclaration = ({form, section, change}) => {
  const {t} = useTranslation();
  const {links} = useGlobal();
  const {sessionId} = useParams();
  const selector = formValueSelector(form);
  const {employerDeclarationChoice} = useSelector((state) => selector(state, "employerDeclaration") || {});
  const replacements = {
    // Supply all links for use in translations
    ...Object.entries(links)
      .map(([name, ref]) => [name, <a rel="noreferrer" style={{color:"black"}} target="_blank" href={ref}> </a>])
      .reduce((acc, [key, comp]) => ({[key]: comp, ...acc}), {}),
      employerTermsAndConditionsText: <Checkbox color="primary" name="employerTermsAndConditions" required validate={acceptance()} />
  }
  const { data: {
            opportunity: {employerAccountName, employerContactName} = {},
            student: {firstName, lastName} = {}
          } = {}
        } = useApplication();

  const employerSignOptions = useMemo(() => {
    const optionsToShow = sessionId !== undefined ? 
      [{label: "Employer Sign Now", value: "809730000"}] : 
      [{label: "Employer Sign Now", value: "809730000"}, {label: "Email Link to Employer", value: "809730001"}];
    return optionsToShow;
    }, [sessionId]);
            
  useEffect(() => {
    if (Array.isArray(employerSignOptions)) {
      if (employerSignOptions.length === 1 && employerDeclarationChoice !== employerSignOptions[0].value) {
        change(`${section}.employerDeclarationChoice`, employerSignOptions[0].value);
      }
    }}, [employerSignOptions, employerDeclarationChoice, change, section]);

  return (
    <BaseSection section={section} title="Employer Declaration">
      <Grid item xs={12}>
        <ChooseOne
          name="employerDeclarationChoice"
          required
          disabled={employerSignOptions.length === 1}
          options={employerSignOptions.map((choice) => ({
            value: choice.value,
            label: choice.label
          }))}
          orientation="horizontal"
        />
      {(employerDeclarationChoice === "809730000") && (
        <Paper elevation={1} xs={12} square>
          <Box padding={3} marginBottom={3} marginTop={3}>
            <Grid item xs={12}>
              <Box marginBottom={3} >
                <Typography variant="h3">{t("EmployerDeclaration.title")}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box marginBottom={1} >
                <Typography variant="h4">{t("EmployerDeclaration.apprentice")}{` ${firstName} ${lastName}`}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box marginBottom={2} >
                <Typography variant="h4">{t("EmployerDeclaration.employer")}{` ${employerContactName} (${employerAccountName})`}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom variant="body1" component="div">
                <Trans t={t} i18nKey={"EmployerDeclaration.declaration.text"} components={replacements} />
              </Typography>
            </Grid>
          </Box>
        </Paper>
        )}
        {(employerDeclarationChoice === "809730001") && (
          <Paper elevation={1} xs={12} square>
          <Box padding={3} marginBottom={3} marginTop={3}>
          <Grid item xs={12}>
            <Trans i18nKey={t("EmployerDeclaration.email")} components={[<b />]} />
          </Grid>
          </Box>
          </Paper>
        )}
      </Grid>
    </BaseSection>
  )
};

export default EmployerDeclaration;

EmployerDeclaration.propTypes = {
  form: PropType.string.isRequired,
  section: PropType.string.isRequired
};

export function mapToApplication({employerTermsAndConditions, employerDeclarationChoice}) {
  return {
    opportunity: 
    employerDeclarationChoice === "809730000" ? 
    {
      employerTermsAndConditions,
      employerDeclarationChoice,
      employerSignedDate: (employerTermsAndConditions && moment().format("YYYY-MM-DD")) || undefined
    } :
    {
      employerDeclarationChoice
    }
  };
}
export function mapFromApplication({
  declaration: {employerTermsAndConditions, employerDeclarationChoice} = {}
}) {
  return {
    employerTermsAndConditions,
    employerDeclarationChoice
  };
}
