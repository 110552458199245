import React, {useState} from 'react';
import PropType from 'prop-types';
import {Grid, Grow} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import {useTranslation} from "react-i18next";
import {BaseSection, TextField, toPhone, TypedPhone, useGlobal} from "up-form";

const EmployerContactDetails = ({section}) => {
    const {t} = useTranslation();
    const [countryValidationError, setCountryValidationError] = useState();
    const {
        component: {
        section: {
            StudentDetails: {checkPhoneCountries}
        }
        }
    } = useGlobal();

    return (
        <BaseSection section={section} title="Employer/ Supervisor (Individual) Details">
            <Grid item xs={12} sm={6}>
				<TextField required fullWidth name="firstname" label="First Name" />
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextField required fullWidth name="lastname" label="Last Name"/>
			</Grid>
			<Grid item xs={12} sm={12}>
				<TextField required fullWidth name="email" label="Email Address" />
			</Grid>
            <Grid item xs={12}>
                <TypedPhone
                required
                fullWidth
                countries={checkPhoneCountries}
                inputProps={{minLength: 5}}
                onCountryValidation={({message}) => {
                    setCountryValidationError(message);
                }}
                label={t("StudentDetails.phone.label")}
                helperText={
                    <Grow mountOnEnter unmountOnExit in={!!countryValidationError} timeout={1000}>
                    <Alert severity="info">{countryValidationError}</Alert>
                    </Grow>
                }
                />
            </Grid>
        </BaseSection>
    );
};

EmployerContactDetails.propTypes = {
    form: PropType.string.isRequired,
    section: PropType.string.isRequired
};

export function mapToEmployer(employerContactDetails) {
    const {firstname, lastname, email} = employerContactDetails
    return {
        employerContact: {
            firstname,
            lastname,
            email,
            ...toPhone(employerContactDetails)
        }
    };
}

export default EmployerContactDetails;