import {Box, CircularProgress, useMediaQuery, useTheme, withStyles} from "@material-ui/core";
import {isEmpty, merge} from "lodash";
import PropType from "prop-types";
import {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Redirect} from "react-router";
import {reduxForm} from "redux-form";
import {getUtmParams, identify, page, track} from "up-analytics";
import UpApi from "up-api";
import {
  BaseEnrolmentForm,
  fromLabel,
  toCourseIntake,
  SkeletonProvider,
  toId,
  Typography,
  useExcludedSet,
  useGlobal
} from "up-form";
import {createApplication, createLead, supportingDocuments, updateApplication, useCourses, useMetadata, createWorkplaceEmployer} from "up-state";
import ApplicationSummary from "../ApplicationSummary";
import CustomStepIcon from "../CustomStepIcon";
import Address from "../section/Address";
import Checkout from "../section/Checkout";
import ChooseCourse from "../section/ChooseCourse";
import ChooseIntake from "../section/ChooseIntake";
import Declaration from "../section/Declaration";
import Portfolio from "../section/Documents";
import Education, {isComplete as educationIsComplete} from "../section/Education";
import EmergencyContact from "../section/EmergencyContact";
import Employment, {isComplete as employmentIsComplete} from "../section/Employment";
import Health from "../section/Health";
import History from "../section/History";
import Language from "../section/Language";
import Personal from "../section/Personal";
import Qualifications from "../section/Qualifications";
import ResidencyStatusAU from "../section/ResidencyStatusAU";
import ResidencyStatusNZ from "../section/ResidencyStatusNZ";
import Signature from "../section/Signature";
import StudentDetails from "../section/StudentDetails";
import EmployerDetails from "../section/EmployerDetails";
import EmployerAddress from "../section/EmployerAddress";
import {mapApplicationToForm, mapFormToApplication, mapFormToLead, mapFormToTask, mapFormToEmployer} from "./mapping/mapApprenticeshipForm";
import EmployerContactDetails from "../section/EmployerContactDetails";
import EmployerDeclaration from "../section/EmployerDeclaration";
const formName = "apprenticeship";
const recordFor = "domestice2e";

/**
 * Hook calculate set step/section exclusions given current form state (application/course) and metadata
 * @param {*} application
 * @param {*} course
 * @param {*} metadata
 * @returns array of keys of the form 'DomesticForm.step.<step>[.<section>]` (convention adopted for Optional in config)
 */
/**
 * Form containing all steps for domestic enrolment
 */
const ApprenticeshipForm = reduxForm({
  form: formName,
  destroyOnUnmount: false
})(
  withStyles(
    (theme) => {
      const summaryAtSide = theme.breakpoints.up("md");
      return {
        form: {
          [summaryAtSide]: {
            maxWidth: "80%"
          }
        },
        summary: {
          [summaryAtSide]: {
            position: "fixed",
            top: "20%",
            right: theme.spacing(3),
            alignSelf: "flex-start",
            padding: theme.spacing(2),
            zIndex: 5,
            maxWidth: "20%"
          }
        }
      };
    },
    {name: "ApprenticeshipForm"}
  )(({form, courseId, productId, intakeId, leadId, dirty, classes, change, clearFields, defaultStep, ...props}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [error, setError] = useState(null);
    const [choiceState, setChoiceState] = useState(null);
    const {data: application} = useSelector((state) => state.application);
    const {data: createLeadResponse} = useSelector((state) => state.createLead);
    const {leadCRMId = leadId} = createLeadResponse || {}; // lead Id from previus lead explicit create
    const {providerSlug} = useGlobal();
    const {data: courses} = useCourses(providerSlug);
    const {data: metadata, data: {statusLocations} = {}} = useMetadata();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
    const { pending: pendingDocs}  = useSelector((state) => state.supportingDocuments);
    const {
      declaration: {enrolmentFormCompleted} = {},
      contacts: {emergency: {stakeholderCRMId} = {}} = {},
      opportunity: {opportunityCRMId, intakeCRMId, isCompanyApplicant, employerAccountName, employerContactName, employerTermsAndConditions} = {},
      payment: {firstPaymentTransactionId, invoiceId} = {},
      student: {
        dateOfBirth,
        permanentAddress: {streetNumberAndName, city, postcode} = {}
      } = {}
    } = application || {};
    const [verificationRequired, setVerificationRequired] = useState(false);
    const {course} =
      (courses && intakeCRMId && toCourseIntake({intakeCRMId}, courses)) || {};
    const excluded = new Set([
      ...useExcludedSet()
    ]);
    const {data: createWorkplaceEmployerResponse} = useSelector((state) => state.createWorkplaceEmployer);
    const {employerAccountId, employerContactId} = createWorkplaceEmployerResponse || {};

    const createOrUpdateApplication = useCallback(
      ({sections, extra = {}}) => {
        if (opportunityCRMId) {
          // Application already successfully created
          if (dirty) {
            // form edited - update new version
            if (sections.employerDeclaration) {
              const {employerDeclarationChoice} = sections.employerDeclaration;
              setChoiceState(employerDeclarationChoice);
            }
            const newApplication = mapFormToApplication(sections, extra, {
              metadata
            });
            return dispatch(
              updateApplication({
                opportunityId: opportunityCRMId,
                body: newApplication
              })
            );
          } else {
            console.debug("No update needed to application");
            return Promise.resolve(true);
          }
        } else if (leadCRMId) {
          // POST a new application
          return dispatch(
            createApplication(
              mapFormToApplication(
                sections,
                merge(
                  {
                    opportunity: {
                      intakeCRMId,
                      leadCRMId,
                      provider: providerSlug,
                      statusLocationId: toId(
                        fromLabel(statusLocations, "Domestic")
                      )
                    }
                  },

                  extra
                ),
                {metadata}
              )
            )
          ).then((newApplication) => {
            const {
              value: {opportunity: {sessionJwt} = {}}
            } = newApplication;
            if (sessionJwt) {
              // we have an initial auth token as it's a new application (no previous application under this identity)
              UpApi.configure({accessToken: sessionJwt});
              console.log("Initial application, verification not needed");
            } else {
              console.log(
                "Application already made under this identoty, verification needed"
              );
              setVerificationRequired(true);
            }
            return newApplication;
          });
        } else {
          console.error("Cannot create application (need leadCRMId)");
        }
      },
      [
        opportunityCRMId,
        dirty,
        dispatch,
        metadata,
        providerSlug,
        leadCRMId,
        intakeCRMId,
        statusLocations
      ]
    );
    const createOrUpdateLead = useCallback(
      ({sections}) => {
        const {leadCRMId} = createLeadResponse || {}; // lead Id from previus lead explicit create
        if (dirty || !leadCRMId) {
          dispatch(createApplication.fulfilled(null)); // new lead will require new Application
          dispatch(updateApplication.fulfilled(null));
          return dispatch(
            createLead(
              mapFormToLead(
                sections,
                {
                  provider: providerSlug,
                  productId,
                  leadSource: "Domestic Application",
                  recordFor: recordFor,
                  intention: "Apply",
                  enquiryNote:
                    "This enquiry came from the online application form",
                  ...getUtmParams()
                },
                {metadata}
              )
            )
          );
        } else console.debug("Using previosly created lead: ", leadCRMId);
      },
      [createLeadResponse, dirty, dispatch, providerSlug, productId, metadata]
    );

    // Some steps may want to add tasks after Next
    const addTask = useCallback(
      ({sections}) => {
        if (dirty && opportunityCRMId) {
          const taskBody = mapFormToTask(
            sections,
            {opportunityCRMId},
            {metadata, application}
          );
          // Create a task if mapped to title.descrption fields
          return taskBody.title && taskBody.description
            ? UpApi.createTask(taskBody)
            : Promise.resolve(true);
        } else return Promise.resolve(true);
      },
      [dirty, opportunityCRMId, metadata, application]
    );

    const createEmployer = useCallback(
      ({sections}) => {
        if (dirty && opportunityCRMId) {
          const employerDetails = mapFormToEmployer(
            sections,
            {opportunityCRMId},
            {metadata, application}
          );
          return dispatch(
            createWorkplaceEmployer({
              employerDetails
            })
          );
        } else {
          console.debug("No update needed to application");
          return Promise.resolve(true);
        }
      },
      [dirty, opportunityCRMId, metadata, application, dispatch]
    )

    const steps = [
      {
        name: "details",
        label: t("ApprenticeshipForm.details.label"),
        sections: {
          details: StudentDetails
        },
        next: {
          label: t("ApprenticeshipForm.details.next.label")
        },
        reset: {},
        submit: ({details}) =>
          createOrUpdateLead({
            sections: {details}
          }),
        completed: leadId || opportunityCRMId
      },
      {
        name: "course",
        label: t("ApprenticeshipForm.course.label"),
        sections: {
          courseDetails: ChooseCourse,
          intake: ChooseIntake
        },
        next: {},
        reset: {},
        submit: ({courseDetails, intake}) =>
          createOrUpdateApplication({
            sections: {courseDetails, details: StudentDetails, intake}
          }),
        completed: !!course
      },
      {
        name: "personal",
        label: t("ApprenticeshipForm.personal.label"),
        sections: {
          personal: Personal,
          residencyStatusNZ: ResidencyStatusNZ,
          residencyStatusAU: ResidencyStatusAU,
          address: Address,
          emergencyContact: EmergencyContact,
          language: Language,
          health: Health,
          history: History
        },
        next: {},
        reset: {},
        submit: ({
          address,
          emergencyContact,
          health,
          history,
          language,
          personal,
          residencyStatusAU,
          residencyStatusNZ
        }) =>
          createOrUpdateApplication({
            sections: {
              address,
              emergencyContact,
              health,
              history,
              language,
              personal,
              residencyStatusAU,
              residencyStatusNZ
            },
            extra: {
              // Pass thru any returned emerg contact id
              contacts: {
                emergency: {
                  stakeholderCRMId
                }
              }
            }
          }),
        completed: dateOfBirth && streetNumberAndName && city && postcode // a required field
      },
      {
        name: "employerDetails",
        label: "Employer Details",
        sections: {
          employerDetails: EmployerDetails,
          employerAddress: EmployerAddress,
          employerContactDetails: EmployerContactDetails
        },
        next: {},
        reset: {},
        completed: (employerContactId && employerAccountId) || (employerAccountName && employerContactName), // a required field
        submit: ({
          employerDetails,
          employerAddress,
          employerContactDetails
        }) =>
          createEmployer({
            sections: {
              employerDetails,
              employerAddress,
              employerContactDetails
            }
          })
      },
      {
        name: "education",
        label: t("ApprenticeshipForm.education.label"),
        next: {},
        reset: {},
        completed:
          educationIsComplete(application, excluded) ||
          employmentIsComplete(application, excluded),
        sections: {
          education: Education,
          qualifications: Qualifications,
          employment: Employment
        },
        submit: ({education, qualifications, employment}) =>
          createOrUpdateApplication({
            sections: {
              education,
              employment,
              qualifications
            }
          })
      },
      {
        name: "documents",
        label: t("ApprenticeshipForm.documents.label"),
        next: {},
        reset: {},
        skippable: true, // Can be skipped on resume as has no required fields
        sections: {
          documents: Portfolio
        },
        submit: ({documents}) => {
          return (
            documents &&
            documents.files &&
            documents.files.length > 0 &&
            opportunityCRMId &&
            dispatch(supportingDocuments(documents.files, opportunityCRMId))
          );
        }
      },
      {
        name: "checkout",
        label: t("ApprenticeshipForm.checkout.label"),
        next: {},
        completed:
          !!firstPaymentTransactionId || (invoiceId && isCompanyApplicant),
        sections: {
          checkout: Checkout
        },
        submit: ({checkout}) =>
          Promise.all([
            createOrUpdateApplication({sections: {checkout}}),
            addTask({sections: {checkout}})
          ])
      },
      {
        name: "declaration",
        label: t("ApprenticeshipForm.declaration.label"),
        next: {},
        reset: {},
        sections: {
          applicationSummary: !isDesktop && ApplicationSummary,
          declaration: Declaration,
          signature: Signature
        },
        submit: ({applicationSummary, declaration, signature}) =>
          createOrUpdateApplication({
            sections: {
              applicationSummary,
              declaration,
              signature
            }
          }),
        completed: enrolmentFormCompleted
      },
      {
        name: "employerDeclaration",
        label: "Employer Declaration",
        next: {label: "Submit"},
        reset: {},
        completed: employerTermsAndConditions,
        sections: {
          employerDeclaration: EmployerDeclaration
        },
        submit: ({employerDeclaration}) => {
        return (
          opportunityCRMId &&
          createOrUpdateApplication({
            sections: {
              employerDeclaration
            }
          })
      )}
    }, 
    {
      name: "complete",
      label: t("ApprenticeshipForm.complete.label"),
      sections: {
        complete:(
          (choiceState === "809730000" || choiceState === "809730001") &&
            <SkeletonProvider busy={pendingDocs }>
              <Redirect to={`/${providerSlug}/complete`} />
            </SkeletonProvider>
          )
      }
    }
    ].map((step) => ({
        ...step,
        sections: Object.entries(step.sections).reduce(
          (acc, [name, section]) => ({
            ...acc,
            ...(!excluded.has(
              `ApprenticeshipForm.step.${step.name}.${name}`
            ) && {[name]: section})
          }),
          {}
        )
      })) // remove unneeded sections from config
      .filter(
        ({name, sections}) =>
          !excluded.has(`ApprenticeshipForm.step.${name}`) &&
          !isEmpty(sections)
      ) // remove excluded or empty steps from config
      .filter(({name}) => !leadId || name !== "details"); // no details step if leadId pre-selected

    // Add a hidden field so if the provider changes, our lead becomes dirty as we can't re-use lead for different provider
    useEffect(() => {
      change("providerSlug", providerSlug);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [providerSlug]);
    const buttonTrackProps = {
      category: "Application Form",
      action: "View "
    };

    // if application data present set initialState null initially so useEffect will map to fields, else just empty
    const [initialState, setInitialState] = useState(application ? null : {});
    useEffect(() => {
      // Resuming form session with application state already loaded (need courses to check we have a valid intake and set course info, and metadata to map ids)
      if (!initialState && courses && metadata) {
        try {
          setInitialState(mapApplicationToForm(application, {courses, metadata}));
          console.debug("Initialized from:", application);
        } catch (error) {
          setError(error.message);
        } // catches mismatched backend course data
      }
    }, [courses, application, initialState, metadata]);

    useEffect(() => {
      // Analytics identification
      const {student: {studentUPId, emailAddress: email, firstName, lastName} = {}} = application || {};
      if (studentUPId) {
        identify(studentUPId, {firstName, lastName, email});
      }
    }, [application]);
    return (
      <>
        {error && <Redirect to={`/${providerSlug}/error/500?message=${error}`} />}
        {verificationRequired && <Redirect to={`/${providerSlug}/verification`} />}
        {isDesktop && <ApplicationSummary className={classes.summary} form={form} />}
        {initialState ? (
          <BaseEnrolmentForm
            defaultStep={defaultStep}
            initialState={initialState}
            onChangeActiveStep={({name, label}) => {
              if (name !== "complete") {
                page("Application Form", `${label} Page`, {leadCRMId, opportunityCRMId, intakeCRMId});
                window.history.replaceState(null, name, `/${providerSlug}/enrolling#${name}`);
              }
            }}
            onReset={(stepIndex) => track("Button Reset", buttonTrackProps)}
            onPrev={(stepIndex) => track("Button Prev", buttonTrackProps)}
            onNext={(stepIndex) => track(`Button ${steps[stepIndex].next.label || "Next"}`, buttonTrackProps)}
            className={classes.form}
            StepIconComponent={CustomStepIcon}
            {...{form, dirty, change, clearFields}}
            {...props}
            steps={steps}
          />
        ) : (
          <Box padding={5}>
            <CircularProgress variant="indeterminate" />
            <Typography variant="body1">Loading...</Typography>
          </Box>
        )}
      </>
    );
  })
);

export default ApprenticeshipForm;

ApprenticeshipForm.propTypes = {
  leadId: PropType.string,
  productId: PropType.string,
  courseId: PropType.string,
  intakeCRMId: PropType.string
};
