import {Grid, withStyles} from "@material-ui/core";
import {useTheme} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import moment from "moment";
import PropTypes from "prop-types";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {formValueSelector} from "redux-form";
import {BaseSection, ChooseOne, Optional, toCourseIntake, Typography, useGlobal} from "up-form";

// Add this to metadata enum LA if we actually want values/labels
const intakeUnavailableCodes = new Set(["809730002" /* international */]);
const intakeIsFullCode = "809730000"; // Yes (there is no code for No apparently ;-)

const ChooseIntake = withStyles(
  (theme) => ({
    rowIntake: {
      width: "100%",
      "& th": {
        width: "1%",
        whiteSpace: "nowrap",
        padding: "0 1em 0 0"
      }
    },
    columnIntake: {
      width: "100%",
      borderSpacing: "1em"
    },

    intakeButton: {minWidth: "10em", width: "100%", borderRadius: 0, borderWidth: "2px"}
  }),
  {name: "UpChooseIntake"}
)(({form, section, change, clearFields, classes}) => {
  const {t} = useTranslation();
  const {component: {ChooseIntake: {intakeOrientationThreshold = 6} = {}} = {}} = useGlobal();
  const theme = useTheme();
  const selector = formValueSelector(form);
  const course = useSelector((state) => selector(state, "courseDetails.course"));
  const campus = useSelector((state) => selector(state, section + ".campus"));
  const intake = useSelector((state) => selector(state, section + ".intake"));
  const availableIntakes =
    course &&
    course.intakeList &&
    course.intakeList.filter(
      ({isIntakeFull, intakeAvailableFor}) => isIntakeFull !== intakeIsFullCode && !intakeUnavailableCodes.has(intakeAvailableFor)
    );
  const campuses =
      availableIntakes &&
      [...new Set(availableIntakes.map((intake) => intake.location).filter((campus) => !!campus))].map((campus) => ({
        value: campus
      })),
    isMobile = useMediaQuery(theme.breakpoints.down("sm")),
    campusesOrientation = isMobile || campuses > intakeOrientationThreshold ? "vertical" : "horizontal",
    intakes =
      availableIntakes &&
      [...new Set(availableIntakes.filter((intake) => intake.location === campus && intake.courseStartDate))].map((intake) => ({
        ...intake,
        startDate: moment(intake.courseStartDate)
      })); // map date to something more useful

  useEffect(() => {
    // check consistency of any current campus with available list
    if (Array.isArray(campuses)) {
      if (campuses.length === 1 && campus !== campuses[0].value) {
        change(`${section}.campus`, campuses[0].value);
      } else if (campuses.length === 0 || (campus && !campuses.find(({value}) => campus === value))) {
        clearFields(false, false, `${section}.campus`);
      }
    }
  }, [campuses, change, section, campus, clearFields]);
  useEffect(() => {
    // check consistency of any current intak with available list
    if (Array.isArray(intakes)) {
      if (intakes.length === 1 && !(intake && intake.intakeCRMId === intakes[0].intakeCRMId))
        change(`${section}.intake`, intakes[0]);
      else if (intakes.length === 0 || (intake && !intakes.find(({intakeCRMId}) => intakeCRMId === intake.intakeCRMId))) {
        clearFields(false, false, `${section}.intake`);
      }
    }
  }, [intakes, change, clearFields, section, intake]);
  return (
    <>
      {course && campuses && (
        <BaseSection section={section} title={t("ChooseIntake.title")}>
          <Optional name="ChooseIntake.campus">
            <Grid item xs={12}>
              {campuses && campuses.length > 0 ? (
                <ChooseOne
                  name="campus"
                  required
                  label={t("ChooseIntake.campus.label")}
                  options={campuses}
                  orientation={campusesOrientation}
                  disabled={campuses.length === 1}
                  onChange={() => {
                    clearFields(false, false, `${section}.intake`);
                  }}
                />
              ) : (
                <Typography color="error">{t("ChooseIntake.campus.error.noCampusesFound")}</Typography>
              )}
            </Grid>
          </Optional>
        </BaseSection>
      )}
    </>
  );
});

ChooseIntake.propTypes = {
  change: PropTypes.any,
  course: PropTypes.object,
  form: PropTypes.string,
  section: PropTypes.string
};

export default ChooseIntake;

export function mapToApplication({intake: {intakeCRMId} = {}}) {
  return {
    opportunity: {
      intakeCRMId
    }
  };
}
export function mapFromApplication({opportunity: {intakeCRMId} = {}}, {courses}) {
  if (intakeCRMId) {
    const {
      intake,
      intake: {location: campus}
    } = toCourseIntake({intakeCRMId}, courses);
    return {
      intake,
      campus
    };
  } else {
    return {};
  }
}
