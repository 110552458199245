/**
 * Main application screen
 */
import MomentUtils from "@date-io/moment";
import {Container, CssBaseline, LinearProgress, Paper} from "@material-ui/core";
import {ThemeProvider} from "@material-ui/core/styles";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import {createBrowserHistory} from "history";
import {default as i18n, default as i18next} from "i18next";
import I18NextXhrBackend from "i18next-xhr-backend";
import React, {Suspense, useRef} from "react";
import {I18nextProvider} from "react-i18next";
import {Provider} from "react-redux";
import {Redirect, Route, Router, Switch, useParams, useRouteMatch} from "react-router-dom";
import {createLogger} from "redux-logger";
import {PersistGate} from "redux-persist/integration/react";
import {init as analyticsInit, trackerMiddleware} from "up-analytics";
import UpApi from "up-api";
import {EditConfig, GlobalProvider, useGlobal} from "up-form";
import {createStore, init as upReducerInit, updateUpSessionId} from "up-state";
import config, {clientConfigKey, isDev, providers} from "../../config";
import {createProviderTheme, rootTheme} from "../../themes";
import ErrorPage from "../error/ErrorPage";
import Complete from "../page/Complete";
import {Index} from "../page/Index";
import Verification from "../page/Verification";
import "./App.css";
import ApprenticeshipAppBar from "./ApprenticeshipAppBar";
import {Enrol} from "./Enrol";
import Footer from "./Footer";
import Head from "./Head";
import {ResumeEnrol} from "./ResumeEnrol";
import {AppInsightsContext, AppInsightsErrorBoundary} from "@microsoft/applicationinsights-react-js";
import {reactPlugin} from "up-analytics";
import {useTranslation} from "react-i18next";
import ResumeAferPaymentComplete from "./ResumeAfterPaymentComplete";

const {
    analytics: {appName, googleAnalyticsId, instrumentationKey, trackEvents},
    upApi,
    global: globalConfig
  } = config,
  {
    stage,
    component: {
      app: {
        App: {persist, defaultStep}
      }
    }
  } = globalConfig,
  {
    baseUri,
    scaffold: {all, stageOnly}
  } = upApi;

UpApi.configure({
  baseUri,
  headers: {source: "apprenticeship-application"},
  scaffold: {
    get: (uri) => {
      const filename = `${uri.replace(/[?&]/g, ".").replace("=", "_")}.json`;
      return (
        (stageOnly && stageOnly.includes(uri) && `/scaffold/${stage}/${filename}`) || // stage specific override
        (all && all.includes(uri) && `/scaffold/${filename}`)
      ); // or universal override if available else use proper API
    }
  }
});
upReducerInit({});

// Use custom history so we can listen for router page events for analytics
const appHistory = createBrowserHistory();
analyticsInit({
  appName,
  history: appHistory,
  instrumentationKey,
  googleAnalyticsId
});

const {store, persistor} = createStore(
  {
    key: "apprenticeship-enrolment",
    whitelist: persist ? ["createLead", "application"] : []
  },
  {
    middleware: [trackerMiddleware(trackEvents), isDev && createLogger({diff: true})]
  }
);

/**
 * Initialize internationalization
 */
i18n.use(I18NextXhrBackend).init({
  lng: "en",
  // ns: ["translation", ...Object.keys(config.global.providers)], can load all up front, but slows down
  fallbackLng: "en",
  fallbackNS: ["translation"],
  debug: isDev,
  interpolation: {
    escapeValue: false
  },
  react: {
    useSuspense: true,
    transKeepBasicHtmlNodesFor: ["br", "strong", "em", "b", "i", "p", "dl", "dt", "dd", "ul", "ol", "li"]
  }
});

const ProviderPage = () => {
  const {provider} = useParams();
  const {groups = []} = useGlobal();
  const header = useRef(null);
  const {path} = useRouteMatch();
  const {t} = useTranslation();

  /**
   * For pages under a specific provider, we add the namesapce of the provider as the default, falling back to
   * the school company group (if defined) and the default translations
   *
   */

  const i18forProvider = i18n.cloneInstance({
    ns: ["translation", ...groups, provider],
    defaultNS: provider,
    fallbackNS: [...groups, "translation"]
  });

  return (
    <GlobalProvider>
      <ThemeProvider theme={(parent) => createProviderTheme(provider)}>
        <CssBaseline />
        <I18nextProvider i18n={i18forProvider}>
          <Head />
          <ApprenticeshipAppBar ref={header} />
          <Paper elevation={0} square>
            <Container style={{minHeight: "calc(100vh - 5em)"}}>
              <AppInsightsErrorBoundary
                onError={() => <Redirect to={`/error/500?message=${encodeURIComponent(t("App.error.exception"))}`} />}
                appInsights={reactPlugin}
              >
                <Switch>
                  <Route exact path={`${path}/error/:status(\\d{3})`} component={ErrorPage} />
                  <Route exact path={`${path}/complete`}>
                    <Complete form="apprenticeship" />
                  </Route>
                  <Route exact path={`${path}/verification`}>
                    <Verification form="apprenticeship" />
                  </Route>
                  <Route exact path={`${path}/(session|enrolling)/:sessionId?`} render={() => <ResumeEnrol header={header} />} />
                  <Route
                    exact
                    path={`${path}`}
                    render={() => {
                      if (!defaultStep) updateUpSessionId(null);
                      return <Enrol header={header} defaultStep={defaultStep} />;
                    }}
                  />
                  <Route path={`${path}/payment/approved/:sessionId`}>
                    <ResumeAferPaymentComplete header={header} />
                  </Route>
                  <Route
                    exact
                    path={`${path}/payment/(cancelled|declined)/:sessionId`}
                    render={() => <ResumeEnrol header={header} />}
                  />
                </Switch>
              </AppInsightsErrorBoundary>
              <Footer />
            </Container>
          </Paper>
        </I18nextProvider>
      </ThemeProvider>
    </GlobalProvider>
  );
};

const JsonEditor = React.lazy(() =>
  import(/* webpackChunkName: "jsoneditor" */ "jsoneditor-react").then(({JsonEditor}) => ({default: JsonEditor}))
);
const ApprenticeshipRoutes = (props) => {
  const {providerSlug} = useGlobal();

  return (
    <Switch>
      <Route exact path={`/error/:status(\\d{3})`} component={ErrorPage} />
      <Route path={`/:provider(${Object.keys(providers).join("|")})`} component={ProviderPage} />
      <Route exact path="/">
        {providerSlug ? <Redirect to={`/${providerSlug}`} /> : <Index />}
      </Route>
      {isDev && (
        <Route exact path="/edit/config">
          <EditConfig config={config} clientConfigKey={clientConfigKey} JsonEditor={JsonEditor} />
        </Route>
      )}
      <Route>
        <Redirect to="/error/404" />
      </Route>
    </Switch>
  );
};

/**
 * Render root of app
 */
function App() {
  return (
    <Suspense fallback={<LinearProgress />}>
      <AppInsightsContext.Provider value={reactPlugin}>
        <I18nextProvider i18n={i18next}>
          <ThemeProvider theme={rootTheme}>
            <Provider store={store}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <PersistGate loading={<LinearProgress />} persistor={persistor}>
                  <GlobalProvider persistor={persistor} {...globalConfig}>
                    <Router history={appHistory}>
                      <ApprenticeshipRoutes />
                    </Router>
                  </GlobalProvider>
                </PersistGate>
              </MuiPickersUtilsProvider>
            </Provider>
          </ThemeProvider>
        </I18nextProvider>
      </AppInsightsContext.Provider>
    </Suspense>
  );
}

export default App;
