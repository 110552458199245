import {green, orange, lightBlue} from "@material-ui/core/colors";
import {createTheme} from "@material-ui/core/styles";
import {responsiveFontSizes} from "@material-ui/core";
import {merge} from "lodash";
import {lighten, darken} from "@material-ui/core/styles/colorManipulator";

const defaultPalette = {
  success: green,
  warning: orange,
  info: lightBlue
};

// A root theme shared by all child pages
const rootThemeData = {
  // spacing: 16,
  typography: {
    fontFamily: ["Montserrat", "sans-serif"].join(","),
    useNextVariants: true,
    h1: {fontSize: "32px", fontWeight: 800},
    h2: {fontSize: "24px", fontWeight: 800},
    h3: {fontSize: "20px", fontWeight: 600},
    h4: {fontSize: "16px", fontWeight: 700},
    h5: {fontSize: "14px", fontWeight: 700},
    h6: {fontSize: "12px", fontWeight: 700}
  },
  palette: {
    primary: {
      main: "#006A90"
    },
    secondary: {
      main: "#555"
    },
    ...defaultPalette
  },
  status: {
    danger: "red"
  },

  overrides: {
    MuiLink: {
      root: {
        whiteSpace: "nowrap"
      }
    },
    MuiFormLabel: {
      root: {
        lineHeight: "1.25em",
        fontWeight: "bold"
      }
    },
    MuiButton: {
      root: {
        fontWeight: "600",
        borderRadius: "2em",
        lineHeight: "2.75em"
      }
    },
    MuiToggleButton: {
      root: {
        "&.Mui-disabled": {
          opacity: "50%"
        }
      }
    },
    MuiTypography: {
      root: {
        "& dt": {
          flexBasis: "20%",
          textAlign: "left",
          padding: "2px 4px",
          fontWeight: "bold"
        },
        "& dd": {
          flexBasis: "70%",
          flexGrow: 1,
          margin: 0,
          padding: "2px 4px"
        },
        "& dl": {
          display: "flex",
          flexFlow: "row wrap"
        }
      }
    },
    UpFieldSet: {
      legend: {
        fontWeight: "bold"
      }
    },
    UpSectionHeading: {
      heading: {
        textTransform: "none"
      }
    }
  }
};

const themeByProvider = {
  "builders-academy-itr": {
    palette: {
      ...defaultPalette,
      primary: {main: "#000000"},
      secondary: {main: "#000000"},
      background: {
        default: "#000000"
      }
    }
  }
};
const rootTheme = createTheme(rootThemeData);

export {rootTheme};
export function createProviderTheme(provider) {
  const theme = responsiveFontSizes(createTheme(merge({}, rootThemeData, themeByProvider[provider]))),
    {
      palette: {
        type,
        tonalOffset,
        background: {paper},
        primary: {light, main, dark}
      }
    } = theme;
  return merge(theme, {
    overrides: {
      MuiLink: {
        root: {
          color: type === "dark" ? dark : light
        }
      },

      MuiPaper: {
        ...[...Array(10)]
          .map((v, i) => ({
            backgroundColor: type === "dark" ? lighten(paper, (tonalOffset * i) / 8) : darken(paper, (tonalOffset * i) / 8)
          }))
          .reduce((a, v, i) => {
            a[`elevation${i}`] = v;
            return a;
          }, {})
      },
      MuiTypography: {
        root: {
          "& ul": {
            listStyle: "none",
            "& li::before": {
              content: '"\\2022"',
              color: main,
              fontWeight: "bold",
              display: "inline-block",
              width: "1em",
              marginLeft: "-1em",
              verticalAlign: "middle"
            }
          }
        }
      }
    }
  });
}
