/**
 * Form for Summary section
 */
import PropType from "prop-types";
import {Trans, useTranslation} from "react-i18next";
import {acceptance} from "redux-form-validators";
import {BaseSection, Checkbox, Typography, useGlobal} from "up-form";
import {Grid, Paper, Box} from "@material-ui/core";

const Declaration = ({form, section}) => {
  const {t} = useTranslation();
  const {links} = useGlobal();
  const replacements = {
    // Supply all links for use in translations
    ...Object.entries(links)
      .map(([name, ref]) => [name, <a rel="noreferrer" style={{color:"black"}} target="_blank" href={ref}> </a>])
      .reduce((acc, [key, comp]) => ({[key]: comp, ...acc}), {}),
    infoProvidedCheck: <Checkbox color="primary" name="agreeDeclaration" required validate={acceptance()} />,
    termsAndConditionsCheck: <Checkbox color="primary" name="agreeTerms" required validate={acceptance()} />,
    apprenticeJourneyCheck: <Checkbox color="primary" name="agreeApprenticeJourney" required validate={acceptance()} />
  };

  return (
    <BaseSection section={section} title={t("Declaration.title")}>
      <Grid item xs={12}>
        <Paper elevation={1} xs={12} square>
          <Box padding={3}>
            <Typography variant="h6">{t("Declaration.text")}</Typography>
            <Typography gutterBottom variant="body1" component="div">
              <Trans t={t} i18nKey={"Declaration.declaration.text"} components={replacements} />
            </Typography>
          </Box>
        </Paper>
      </Grid>
    </BaseSection>
  );
};

export default Declaration;

Declaration.propTypes = {
  form: PropType.string.isRequired,
  section: PropType.string.isRequired
};

export function mapToApplication({agreeTerms, agreeDeclaration}) {
  return {
    declaration: {
      agreeTerms,
      agreeDeclaration
    }
  };
}
export function mapFromApplication({
  declaration: {agreeTerms, agreeDeclaration} = {}
}) {
  return {
    agreeTerms,
    agreeDeclaration
  };
}